import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit"
import { useFlexSearch } from 'react-use-flexsearch'
import { Formik, Form, Field } from 'formik'
import PostCard from "../components/ARGAPostCard"
import ARGAPaginationNav from "../components/ARGAPaginationNav"
import { getImage } from "gatsby-plugin-image"

const BlogList = (props) => {
    /* const pageSize = parseInt(process.env.GATSBY_PAGE_SIZE); */
    const pageSize = 6;
    const pageCount = Math.ceil(props.data.allMarkdownRemark.totalCount / pageSize);
    const posts = props.data.allMarkdownRemark.edges;
    const currentPage = props.pageContext.currentPage;
    const prevUrl = currentPage === 1 ? null : currentPage === 2 ? `/blog` : `/blog/${currentPage - 1}`;
    const postUrl = currentPage === pageCount ? null : `/blog/${currentPage + 1}`;
    const [query, setQuery] = useState(null)

    const index = props.data.localSearchPages.index;
    const store = props.data.localSearchPages.store;
    const results = useFlexSearch(query, index, store);
    console.log(results);
    
    return (
        <>
          <Seo title="Blog ARGA Intercultural, un espacio para aprender ingles gratis" />
          {/* highlight-start */}
          <h1 className='d-none'>Blog</h1>
          <MDBContainer breakpoint="xxl" className="my-5 pt-5">
              <MDBRow className="mb-0 ">
                <MDBCol lg={4} className="d-flex justify-content-start">
                <Formik 
                  initialValues={{ query: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    setQuery(values.query)
                    setSubmitting(false)
                  }}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <MDBIcon fas icon='search' className="mx-2 pt-2"/>
                    <Form>
                      <Field className='rounded' name="query" onInput={(values) => {setQuery(values.target.value)}} />
                    </Form>
                  </div>
                </Formik>                
                </MDBCol>
                <MDBCol lg={4}>
                <ARGAPaginationNav
                  currentPage={currentPage} 
                  pageCount={pageCount}
                  prevUrl={prevUrl}
                  postUrl={postUrl}
                  isBottom={false}
                />
                </MDBCol>
                <MDBCol lg={4}></MDBCol>
            </MDBRow>

            {
              Array.isArray(results) && results.length ?
                <MDBRow >
                  {results.map(result => (
                    <MDBCol lg={4} md={4} className="mb-2 py-2 px-1">
                      <PostCard frontmatter={result} excerpt={result.excerpt} image={getImage(result.hero_image)} />
                    </MDBCol>
                  ))}
                </MDBRow>
                :            
                <MDBRow >
                  {posts.map(({ node }) => (
                    <MDBCol lg={4} md={4} className="mb-2 py-2 px-1">
                      <PostCard frontmatter={node.frontmatter} excerpt={node.excerpt} image={getImage(node.frontmatter.hero_image)} />
                    </MDBCol>
                ))}       
                </MDBRow>
            }
            
            <MDBRow className="mb-0 mt-5">
              <ARGAPaginationNav
                currentPage={currentPage} 
                pageCount={pageCount}
                prevUrl={prevUrl}
                postUrl={postUrl}
                isBottom={true}
              />
            </MDBRow>
          </MDBContainer>
      </>
    )
}
export const blogListQuery = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
    localSearchPages {
      index
      store
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            slug
            date(formatString: "DD/MM/YYYY")
            author
            status
            type
            hero_image {
              childImageSharp {
                gatsbyImageData(
                  width: 1170
                  height: 390
                )
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`

export default BlogList