import { Link } from "gatsby";
import {
  MDBIcon,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import React from "react";

const ARGAPaginationNav = ({
  pageCount,
  prevUrl,
  postUrl,
  isBottom,
  currentPage,
}) => {
  if (isBottom) {
    return (
      <nav aria-label="Blog navigation links" className="pb-3 mb-3 mt-0 pt-0">
        <MDBPagination size="sm" center>
          <MDBPaginationItem>
            <Link to={prevUrl}>
              <MDBPaginationLink>
                <MDBIcon far icon="caret-square-left" className="pe-5" />
              </MDBPaginationLink>
            </Link>
          </MDBPaginationItem>
          {[...Array(pageCount)].slice(0, 5).map((_, i) => {
            const pageUrl = i === 0 ? `/blog` : `/blog/${i + 1}`;
            let isCurrentPage = false;
            currentPage === i + 1
              ? (isCurrentPage = true)
              : (isCurrentPage = false);
            return (
              <MDBPaginationItem active={isCurrentPage}>
                <Link to={pageUrl}>
                  <MDBPaginationLink>{i + 1}</MDBPaginationLink>
                </Link>
              </MDBPaginationItem>
            );
          })}
          <MDBPaginationItem>
            <Link to={postUrl}>
              <MDBPaginationLink>
                <MDBIcon far icon="caret-square-right" className="ps-5" />
              </MDBPaginationLink>
            </Link>
          </MDBPaginationItem>
        </MDBPagination>
      </nav>
    );
  } else {
    return (
      <nav aria-label="Blog navigation links" className="mb-0 pb-1 mt-0">
        <MDBPagination size="sm" center>
          <MDBPaginationItem>
            <Link to={prevUrl}>
              <MDBPaginationLink>
                <MDBIcon far icon="caret-square-left" className="pe-5" />
              </MDBPaginationLink>
            </Link>
          </MDBPaginationItem>
          {[...Array(pageCount)].slice(0, 5).map((_, i) => {
            const pageUrl = i === 0 ? `/blog` : `/blog/${i + 1}`;
            let isCurrentPage = false;
            currentPage === i + 1
              ? (isCurrentPage = true)
              : (isCurrentPage = false);
            return (
              <MDBPaginationItem active={isCurrentPage}>
                <Link to={pageUrl}>
                  <MDBPaginationLink>{i + 1}</MDBPaginationLink>
                </Link>
              </MDBPaginationItem>
            );
          })}
          <MDBPaginationItem>
            <Link to={postUrl}>
              <MDBPaginationLink>
                <MDBIcon far icon="caret-square-right" className="ps-5" />
              </MDBPaginationLink>
            </Link>
          </MDBPaginationItem>
        </MDBPagination>
      </nav>
    );
  }
};

export default ARGAPaginationNav;
